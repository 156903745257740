import { Elm } from "./Main.elm";
import * as serviceWorker from "./serviceWorker";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";

const app = Elm.Main.init({
  node: document.getElementById("root"),
});

if (app.ports.initScreenshotSlider) {
  app.ports.initScreenshotSlider.subscribe(async (id) => {
    const initSlider = () => {
      const element = document.getElementById(id);

      if (element) {
        new Glide("#" + id, {
          type: "carousel",
          autoplay: 5000,
        }).mount();
      } else {
        setTimeout(initSlider, 100);
      }
    };

    initSlider();
  });
}

if (app.ports.initDescriptionSlider) {
  app.ports.initDescriptionSlider.subscribe((id) => {
    const initSlider = () => {
      const element = document.getElementById(id);

      if (element) {
        new Glide("#" + id, {
          type: "slider",
          peek: 100,
          animationDuration: 600,
          breakpoints: {
            1000: {
              peek: 0,
            },
          },
        }).mount();
      } else {
        setTimeout(initSlider, 100);
      }
    };

    initSlider();
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
